
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StockAvailabilityTable2 from '@/components/reports-v2/components/codedWidgets/stock/evergreen/StockAvailabilityTable2.vue';
import GeneralFilterable from '@/components/reports-v2/components/filterables/GeneralFilterable.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import LastSync from '@/components/LastSync.vue';
import gdbx from '@/store/modules/gdbx';
import { ValueText } from '@/store/models.def';

@Component({
	components: {
		StockAvailabilityTable2,
		SelectFilter,
		LastSync,
		GeneralFilterable,
	},
})
export default class EgStockAvailability extends Vue {
	public selectedGSMList: number[] = [];
	public selectedSizeList: string[] = [];
	public selectedBrandList: string[] = [];
	public selectedGSM: number | null = 0;
	public selectedSize: string | null = '';
	public selectedBrand: string | null = '';
	public defaultStockGroup: string = 'DUPLEX BOARD';
	public isDataReady: boolean = false;

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get allStockGroups() {
		return gdbx.allStockGroups;
	}

	@Watch('allStockGroups', { immediate: true })
	public onAllStockGroupsChanged(newVal: ValueText[]) {
		if (newVal.length > 0) {
			this.isDataReady = true;
		}
	}

	public get options() {
		if (this.isDataReady) {
			return this.allStockGroups.map((item) => item.text);
		}
	}

	public get selectedItems() {
		if (this.isDataReady) {
			return this.allStockGroups.filter(
				(vt) => vt.text === this.defaultStockGroup,
			)[0]!.value;
		}
		return [];
	}

	public receivedGSMList(value: number[]) {
		this.selectedGSMList = value;
	}

	public receivedSizeList(value: string[]) {
		this.selectedSizeList = value;
	}

	public receivedBrandList(value: string[]) {
		this.selectedBrandList = value;
	}

	@Watch('defaultStockGroup', { immediate: true })
	public onDefaultStockGroupChanged(newVal) {
		this.selectedBrand = null;
		this.selectedGSM = null;
		this.selectedSize = null;
	}

	@Watch('selectedGSM')
	public onSelectedGSMChanged(newVal) {
		this.selectedSize = null;
	}

	@Watch('selectedBrand')
	public onSelectedBrandChanged(newVal) {
		this.selectedGSM = null;
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
