
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	PermissionsGroup,
	StockUtdBalance,
	StockPurchaseOrder,
} from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import { addComma } from '@/util/number';

@Component({
	components: {
		TableFilterable,
	},
})
export default class StockAvailabilityTable2 extends FilterWidget {
	@Prop({ default: () => [] })
	public readonly selectedItems!: string[];
	@Prop({ default: '' })
	public readonly selectedGSM!: string;
	@Prop({ default: '' })
	public readonly selectedSize!: string;
	@Prop({ default: '' })
	public readonly selectedBrand!: string;

	public get permissionIds(): PermissionsGroup[] {
		return ['stocks'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public tableItems: Array<{
		item_code: string;
		item_description: string;
		avail_qty: number;
		remark_1: string;
		remark_2: string;
		incoming_Qty: number;
		items: any;
	}> = [];
	public tableFields: any[] = [];
	public detailFields: any[] = [];
	public GSMList: number[] = [];
	public sizeList: string[] = [];
	public brandList: string[] = [];

	@Watch('GSMList', { immediate: true })
	public onGSMListChanged(newVal) {
		this.$emit('returnSelectedGSMList', newVal);
	}

	@Watch('sizeList', { immediate: true })
	public onSizeListChanged(newVal) {
		this.$emit('returnSizeList', newVal);
	}

	@Watch('brandList', { immediate: true })
	public onBrandListChanged(newVal) {
		this.$emit('returnBrandList', newVal);
	}

	public format(
		value: number,
		decimal: number = 2,
		forceDecimal: boolean = true,
	) {
		return addComma(value, decimal, forceDecimal);
	}

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get expensiveHook() {
		const { selectedItems, selectedGSM, selectedSize, selectedBrand } = this;
		return JSON.stringify([
			selectedItems,
			selectedGSM,
			selectedSize,
			selectedBrand,
		]);
	}

	public async expensiveCalc() {
		const ref = FilteredDatabase.ref('stockUtdBalance').stockItems(
			this.selectedItems,
		);

		let initCards: StockUtdBalance[] = await (await ref.get()).getCards();

		initCards = initCards.filter(
			(item) => item.quantity > 0 || item.poQuantity > 0,
		);

		const itemList = initCards.map((item) => item.itemCode);

		const ref2 = FilteredDatabase.ref('stockUtdBalance').stockItems(itemList);

		this.brandList = [...new Set(initCards.map((item) => item.brand))];

		const brandRef = this.selectedBrand
			? ref2.clone().includes('brand', [this.selectedBrand])
			: ref2;

		this.GSMList = [...(await (await brandRef.get()).getSet('gsm'))].sort(
			(a, b) => a - b,
		);

		const gsmRef = brandRef.clone().includes('gsm', [this.selectedGSM]);

		this.sizeList = [...(await (await gsmRef.get()).getSet('size'))].sort(
			(a, b) => a - b,
		);

		const itemList2 = [...(await (await gsmRef.get()).getSet('itemCode'))];

		const gsmRef2 = FilteredDatabase.ref('stockUtdBalance')
			.stockItems(itemList2)
			.includes('gsm', [this.selectedGSM]);

		const sizeRef = gsmRef2.clone().includes('size', [this.selectedSize]);

		let cards: StockUtdBalance[] = [];
		let poCards: StockPurchaseOrder[] = [];

		if (this.selectedGSM !== null && this.selectedSize !== null) {
			cards = await (await sizeRef.get()).getCards();
		} else if (
			this.selectedBrand !== null &&
			(this.selectedGSM === null || this.selectedGSM === '')
		) {
			cards = await (await brandRef.get()).getCards();
		} else if (
			(this.selectedBrand !== null || this.selectedBrand !== '') &&
			this.selectedGSM !== null &&
			this.selectedSize === null
		) {
			cards = await (await gsmRef2.get()).getCards();
		} else {
			cards = await (await ref2.get()).getCards();
		}

		const poItemCodeList = cards.map((item) => item.itemCode);

		const poRef =
			FilteredDatabase.ref('stockPurchaseOrder').stockItems(poItemCodeList);

		poCards = await (await poRef.get()).getCards();

		const result = {};

		for (const po of poCards) {
			if (!result[po.itemCode]) {
				result[po.itemCode] = [];
			}
			result[po.itemCode].push(po);
		}

		this.tableItems = [];

		for (const item of cards) {
			let poObject: any[] = [];
			for (const poItem in result) {
				if (item.itemCode === poItem) {
					const poCards2: StockPurchaseOrder[] = result[poItem];

					poObject = poCards2.map((i) => ({
						PO_NO: i.docNo,
						quantity: i.quantity,
						ETA: i.deliveryDate,
						remark_1: i.remark1,
					}));
				}
			}

			const object = {
				item_code: item.itemCode,
				item_description: item.itemDesc,
				avail_qty: item.quantity,
				remark_1: item.remark1,
				remark_2: item.remark2,
				incoming_Qty: item.poQuantity,
				items: poObject,
			};

			this.tableItems.push(object);
		}

		this.generateLoadingText(1);

		this.tableFields = [
			{ key: 'item_description', sortable: true, stickyColumn: true },
			{
				key: 'avail_qty',
				label: 'Avail.Qty',
				sortable: true,
				formatter: TableItemFormatter.basic,
			},
			{
				key: 'remark_1',
				sortable: true,
			},
			{
				key: 'incoming_Qty',
				sortable: true,
			},
			{
				key: 'remark_2',
				sortable: true,
			},
		];

		this.detailFields = [
			{ key: 'PO_NO', label: 'PO No.', sortable: true },
			{
				key: 'quantity',
				sortable: true,
				formatter: TableItemFormatter.basic,
			},
			{
				key: 'ETA',
				sortable: true,
				formatter: TableItemFormatter.date3,
			},
			{
				key: 'remark_1',
				sortable: true,
			},
		];

		this.saveHistory(
			'tableItems',
			'tableFields',
			'detailFields',
			'GSMList',
			'sizeList',
			'brandList',
		);
	}
}
